<template>
  <div v-if="!loadingInicial">
    <div class="p-lg-3 d-none d-sm-block">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-5 font-weight-bold text-left">
          Agendamento de Televet Play
        </h3>
      </div>
    </div>
    <div class="p-3 d-block d-sm-none">
      <div class="row justify-content-center">
        <h3 class="font-weight-bold text-center">
          Agendamento de Televet Play
        </h3>
      </div>
    </div>
    <v-divider></v-divider>
    <div
      class="row justify-content-center align-items-end mt-4"
      v-if="clienteTermosValido"
    >
      <div class="col-11 col-sm-4 style-form form-group clearfix mb-0">
        <label for="">Selecionar Especialidade</label>
        <v-select
          style="padding-top: 0 !important; margin-top: 15px;"
          :items="especialidades"
          v-model="especialidade"
        />
      </div>
      <div
        class="col-11 col-sm-4 style-form flex-collunm-reverse 
      form-group clearfix mb-0"
      >
        <label for="">Data agendamento</label>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dataFormatada"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dataAgendamento"
            color="#1daf80"
            :min="amanha()"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </div>

      <!-- <div class="col-4 form-group clearfix mb-0">
        <v-text-field
          v-model="data"
          placeholder="Data"
          :rules="[rulesData.required, rulesData.min, rulesData.max]"
          clear-icon="clear"
          style="margin: 0 5px; padding-top: 5px !important"
          :type="'date'"
          :min="new Date().toISOString().substr(0, 10)"
          :outlined="false"
        />
      </div> -->
      <div class="col-11 col-sm-1 form-group clearfix mb-3">
        <v-btn
          class="mb-2 btn d-none d-sm-flex"
          dark
          color="#1daf80"
          :disabled="data == ''"
          @click="pesquisarAgenda"
        >
          buscar
          <v-icon class="ml-2" small> mdi-magnify </v-icon>
        </v-btn>
        <v-btn
          color="#1DAF80"
          dark
          class="mb-2 btn-mob d-flex d-sm-none"
          :disabled="data == ''"
          @click="pesquisarAgenda"
        >
          Buscar
        </v-btn>
      </div>
    </div>
    <div v-else>
      <h5 class="mb-5 font-weight-bold text-center">
        Para efetuar Agendamento TeleVet Play é necessario aceitar os termos de
        uso e contrato TeleVet Play
      </h5>
      <v-btn
        class="my-2"
        outlined
        color="#1daf80"
        @click="dialogValidarTermos = true"
      >
        Termos de Uso e Contrato
      </v-btn>
    </div>
    <div v-if="!loadTable">
      <div
        class="mt-5"
        v-if="listaAgenda.length >= 0 && listaAgenda[0] != undefined"
      >
        <!-- <div class="row justify-content-end">
          <div class="col-4 text-end">
            <v-template v-for="(valor, index) in historico" :key="index">
              <v-btn
                class="ml-1"
                v-if="validarHistorico(index)"
                color="#1daf80"
                fab
                dark
                outlined
                small
                @click="paginacaoAgenda(valor, index)"
              >
                <font class="btn-number">{{ index + 1 }}</font>
              </v-btn>
              <span class="ml-1" v-if="index == 0 && historico.length >= 4">
                ...
              </span>
            </v-template>
            <v-btn
              v-if="proximaData"
              class="ml-2"
              color="#1daf80"
              fab
              dark
              outlined
              small
              @click="paginacaoAgenda(proximaData)"
            >
              <v-icon small> mdi-arrow-right-bold </v-icon>
            </v-btn>
          </div>
        </div> -->
        <!-- Desktop -->
        <div class="row d-none d-sm-flex">
          <v-card
            elevation="3"
            class="mx-auto"
            width="240"
            v-for="agenda in listaAgenda"
            :key="agenda.dia"
          >
            <v-card-text class="p-0 pb-3">
              <div class="header-day">
                {{ formatarDia(agenda.dia) }}
              </div>
            </v-card-text>
            <div class="px-2">
              <v-card
                elevation="2"
                class="mb-2"
                :class="horario.cliente_id == 0 ? ' bg-create' : ' bg-edit'"
                v-for="horario in agenda.horarios"
                :key="horario.id"
              >
                <v-card-text class="text-start pb-0">
                  <h6 v-if="horario.encaixe == 1">ENCAIXE <br /></h6>
                  <h5>
                    {{ formatarHora(horario.hora_inicio) }} -
                    {{ formatarHora(horario.hora_final) }}
                  </h5>
                  <h6 v-if="horario.cliente_id != 0">
                    Tutor: {{ horario.cliente_nome }} <br />
                    Pet: {{ horario.pet_nome }}<br />
                  </h6>
                  <h5>Médico: {{ horario.nome_medico }}</h5>
                </v-card-text>
                <div class="text-end p-2">
                  <v-template>
                    <v-btn
                      class="mx-2"
                      color="#1daf80"
                      fab
                      dark
                      x-small
                      @click="cadastrarConsulta(horario)"
                    >
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                  </v-template>
                </div>
              </v-card>
            </div>
          </v-card>
        </div>
        <!-- Mobile -->
        <div class="row d-block d-sm-none">
          <v-template
            elevation="3"
            class="mx-auto"
            width="240"
            v-for="agenda in listaAgenda"
            :key="agenda.dia"
          >
            <div
              elevation="2"
              class="mb-5 card-mobile"
              :class="horario.cliente_id == 0 ? ' bg-create' : ' bg-edit'"
              v-for="horario in agenda.horarios"
              :key="horario.id"
            >
              <div class="dia header-day">
                <div>
                  {{ formatarDiaMob(agenda.dia, "dia") }}
                </div>
                <div>
                  {{ formatarDiaMob(agenda.dia, "mes") }}
                </div>
              </div>
              <div class="info-agendamento">
                <div>
                  <h6 v-if="horario.encaixe == 1">ENCAIXE <br /></h6>
                  <h5>
                    {{ formatarHora(horario.hora_inicio) }} -
                    {{ formatarHora(horario.hora_final) }}
                  </h5>
                  <h6 v-if="horario.cliente_id != 0">
                    Tutor: {{ horario.cliente_nome }} <br />
                    Pet: {{ horario.pet_nome }}<br />
                  </h6>
                  <h5 class="medico">
                    <span>Médico:</span> {{ horario.nome_medico }}
                  </h5>
                </div>
                <div>
                  <v-btn
                    class="mx-2"
                    color="#1daf80"
                    fab
                    dark
                    x-small
                    @click="cadastrarConsulta(horario)"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-template>
        </div>
      </div>
      <div v-else>
        <h3 v-if="pesquisa" class="text-center">Nenhuma Agenda encontrada!</h3>
      </div>
    </div>
    <div v-else>
      <v-progress-circular
        width="3"
        size="80"
        indeterminate
        color="green"
      ></v-progress-circular>
    </div>
    <div>
      <v-dialog
        persistent
        fullscreen
        v-model="dialogCadastrarConsulta"
        max-width="500px"
      >
        <cadastrar-consulta-especialidade
          :horarioProp="horario"
          v-if="dialogCadastrarConsulta"
          @close="closeDialogCadastrarConsulta"
          @reagendar="reagendarModal"
          @cadastro="validarPagamento($event)"
        />
      </v-dialog>
      <v-dialog v-model="dialogReagendar" max-width="500px">
        <v-card>
          <v-toolbar style="background-color: #1daf80; color: #fff">
            <v-btn icon dark @click="closeDialogReagendar">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              <span class="headline">Reserva Não Disponível </span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <div class="row justify-content-center align-items-center  mt-2">
              <div class="col-10 col-sm-12 text-center">
                <strong
                  >Gostaríamos de informar que o horário reservado para este
                  agendamento já está ocupado.<br />
                  Por favor, selecione um novo horário e tente
                  novamente.</strong
                >
              </div>
            </div>
            <div class="row justify-content-center align-items-center mb-1">
              <div class="col-10 col-sm-6 text-center">
                <button
                  @click="closeDialogReagendar"
                  class="btn btn-app-primary btn-color-enc font-weight-bold"
                >
                  <span>Fechar</span>
                </button>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogVerConsulta" max-width="500px">
        <ver-consulta-especialidade
          :horarioProp="horario"
          v-if="dialogVerConsulta"
          @close="closeDialogVerConsulta"
        />
      </v-dialog>
      <v-dialog
        persistent
        fullscreen
        v-model="dialogValidarConsulta"
        max-width="500px"
      >
        <dialog-modalidade
          :infos="infos"
          v-if="dialogValidarConsulta"
          @close="closeDialogValidarConsulta"
          @fechar="dialogValidarConsulta = false"
          @confirmado="consultaConfirmada"
        />
      </v-dialog>
      <v-dialog
        persistent
        v-model="dialogEncerrarAgendamento"
        max-width="500px"
      >
        <dialog-encerrar-agendamento
          :infos="infos"
          v-if="dialogEncerrarAgendamento"
          @close="dialogEncerrarAgendamento = false"
          @confirmado="closeDialogEncerrarAgendamento"
        />
      </v-dialog>
    </div>
    <v-dialog
      persistent
      fullscreen
      v-model="dialogValidarTermos"
      max-width="500px"
    >
      <dialog-termos
        v-if="dialogValidarTermos"
        @close="dialogValidarTermos = false"
        @cadastrado="(dialogValidarTermos = false), checkarTermos()"
      />
    </v-dialog>
  </div>
  <div v-else>
    <v-progress-circular
      width="3"
      size="80"
      indeterminate
      color="green"
    ></v-progress-circular>
  </div>
</template>

<script>
import EspecialidadeService from "@/services/especialidade_service.js";
import AgendaEspecialidadeService from "@/services/agendamento_especialidade_recepcao_service.js";
import cadastrarConsultaEspecialidade from "@/components/agendamento_televet/cadastrar_agendamento_especialidade";
import verConsultaEspecialidade from "@/components/agendamento_televet/ver_agendamento_especialidade";
import DialogModalidade from "@/components/pagamento/dialog_confirmar_consulta.vue";
import DialogTermos from "@/components/termos_contratos/dialog_termos_contrato_agendamento.vue";
import DialogEncerrarAgendamento from "@/components/pagamento/dialog_encerrar_agendamento.vue";
import UsuarioService from "@/services/usuario_service.js";

export default {
  components: {
    cadastrarConsultaEspecialidade,
    verConsultaEspecialidade,
    DialogEncerrarAgendamento,
    DialogModalidade,
    DialogTermos,
  },
  data: () => ({
    menu: false,
    dataAgendamento: "",
    dataFormatada: "",
    loadingInicial: false,
    loadTable: false,
    pesquisa: false,
    infos: {},
    data: "",
    dataPesquisa: "",
    dataProxima: "",
    dataAnterior: "",
    especialidade: 0,
    especialidadePesquisa: 0,
    especialidades: [],
    dialogReagendar: false,
    dialogCadastrarConsulta: false,
    dialogVerConsulta: false,
    dialogValidarConsulta: false,
    dialogValidarTermos: false,
    dialogEncerrarAgendamento: false,
    clienteTermosValido: false,
    proximaData: "",
    unidadeId: JSON.parse(sessionStorage.vuex).unidade.id,
    perfilId: JSON.parse(sessionStorage.vuex).perfil.id,
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
    rulesData: {
      required: (value) => !!value || "Campo obrigatório.",
      // data Deve d +1
      min: (value) => value.length >= 10 || "Data inválida.",
      max: (value) => value.length <= 10 || "Data inválida.",
    },
    services: {
      agendaEspecialidadeService: AgendaEspecialidadeService.build(),
      especialidadeService: EspecialidadeService.build(),
      usuarioService: new UsuarioService(),
    },
    listaAgenda: [],
    historico: [],
    meses: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    statusItems: [
      {
        value: 1,
        text: "A Confirmar",
      },
      {
        value: 2,
        text: "Confirmado",
      },
      {
        value: 3,
        text: "Atendido",
      },
      {
        value: 4,
        text: "Cancelado",
      },
      {
        value: 5,
        text: "Remarcado",
      },
      {
        value: 6,
        text: "Não Compareceu",
      },
    ],
  }),
  mounted() {
    this.data = new Date().toLocaleDateString();
    this.dataAgendamento = new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10);
    this.getEspecialidadesbyUnidade();
    this.checkarTermos();
    // this.getEspecialidades();
  },
  computed: {},
  watch: {
    dataAgendamento(val) {
      this.dataFormatada = this.formatDate(val);
    },
  },
  methods: {
    checkarTermos() {
      this.services.usuarioService
        .getAceiteTermo(JSON.parse(sessionStorage.vuex).usuario.dados.clienteId)
        .then(async (response) => {
          const { data } = await response.json();
          if (data[0].aceite_termo == 1) {
            this.clienteTermosValido = true;
            this.dialogValidarTermos = false;
          } else {
            this.clienteTermosValido = false;
            this.dialogValidarTermos = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeDialogCadastrarConsulta() {
      this.dialogCadastrarConsulta = false;
      this.pesquisarAgenda();
      this.horario = {};
    },
    reagendarModal() {
      this.dialogCadastrarConsulta = false;
      this.dialogReagendar = true;
      this.pesquisarAgenda();
      this.horario = {};
    },
    closeDialogVerConsulta() {
      this.dialogVerConsulta = false;
      this.pesquisarAgenda();
      this.horario = {};
    },
    closeDialogValidarConsulta() {
      this.dialogEncerrarAgendamento = true;
    },
    closeDialogReagendar() {
      this.dialogReagendar = false;
      this.pesquisarAgenda();
      this.horario = {};
      // this.limparPesquisa()
    },
    closeDialogEncerrarAgendamento() {
      this.dialogValidarConsulta = false;
      this.dialogEncerrarAgendamento = false;
      this.pesquisarAgenda();
      this.horario = {};
    },
    async pesquisarAgenda() {
      this.loadTable = true;
      this.pesquisa = true;
      this.listaAgenda = [];
      this.historico = [];
      this.especialidadePesquisa = this.especialidade;
      // this.dataPesquisa = this.data;
      this.dataPesquisa = this.dataAgendamento;
      await this.services.agendaEspecialidadeService
        .getAgendaEspecialidadebyTelevet(
          24,
          this.especialidadePesquisa,
          this.dataPesquisa
        )
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          for (let index = 0; index < data.agenda.length; index++) {
            if (data.agenda[index].horarios.length > 0) {
              this.listaAgenda.push(data.agenda[index]);
            }
          }
          // this.listaAgenda = data.agenda;
          this.proximaData = data.proxima_data;
          this.salvarData(this.dataPesquisa);
        })
        .finally(() => {
          this.loadTable = false;
        });
    },
    async paginacaoAgenda(dataPag, index) {
      if (index != undefined) {
        this.limparHistorico(index);
      }
      this.loadTable = true;
      this.listaAgenda = [];
      await this.services.agendaEspecialidadeService
        .getAgendaEspecialidadebyRecepcao(
          24,
          this.especialidadePesquisa,
          dataPag
        )
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.proximaData = data.proxima_data;
          this.listaAgenda = data.agenda;
          this.salvarData(dataPag);
        })
        .finally(() => {
          this.loadTable = false;
        });
    },
    async getEspecialidadesbyUnidade() {
      await this.services.agendaEspecialidadeService
        .getAgendaEspecialidadeByUnidadeId(24)
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            // removendo especialidade 'Teleorientação SOS 32'
            if (element.id_especialidade != 32) {
              this.especialidades.push({
                text: element.descricao,
                value: element.id_especialidade,
              });
            }
          }
          // this.especialidades = data.map((especialidade) => {
          //   // removendo especialidade 'Teleorientação 32'
          //   if (especialidade.id_especialidade != 32) {
          //     return {
          //       text: especialidade.descricao,
          //       value: especialidade.id_especialidade,
          //     };
          //   }
          //});
        });
    },
    cadastrarConsulta(horario) {
      this.horario = horario;
      this.dialogCadastrarConsulta = true;
    },
    verConsulta(horario) {
      this.horario = horario;
      this.dialogVerConsulta = true;
    },
    formatarDia(data) {
      let arrData = data.split("-");
      return `${arrData[2]}/${this.meses[parseInt(arrData[1]) - 1]}`;
    },
    formatarDiaMob(data, tipoData) {
      let arrData = data.split("-");
      if (tipoData == "dia") {
        return `${arrData[2]}/`;
      }
      if (tipoData == "mes") {
        return this.meses[parseInt(arrData[1]) - 1];
      }
      return "";
    },
    formatarHora(hora) {
      return hora.substring(0, hora.length - 3);
    },
    validarHistorico(index) {
      if (
        index == 0 ||
        index == this.historico.length - 2 ||
        index == this.historico.length - 1
      ) {
        return true;
      }
      return false;
    },
    limparHistorico(valor) {
      valor = valor - 1;
      const limitador = this.historico.length;
      for (let index = valor; index < limitador; index++) {
        this.historico.pop();
      }
    },
    salvarData(dataPesquisada) {
      this.historico.push(dataPesquisada);
    },
    statusRetorno(status) {
      let objStatus = this.statusItems.find(
        (element) => element.value == status
      );
      return objStatus.text;
    },
    statusColor(status) {
      let classStyle = "";
      switch (status) {
        case "1":
          classStyle = "#45268a";
          break;
        case "2":
          classStyle = "#5ed98d";
          break;
        case "3":
          classStyle = "#17773c";
          break;
        case "4":
          classStyle = "#9f1c1c";
          break;
        case "5":
          classStyle = "#4f96a6";
          break;
        case "6":
          classStyle = "#4d4d4d";
          break;
      }
      return classStyle;
    },
    validarPagamento(info) {
      this.dialogCadastrarConsulta = false;
      this.infos = info;
      this.dialogValidarConsulta = true;
    },
    amanha() {
      let amanha = new Date();
      // amanha.setDate(amanha.getDate() + 1);
      return new Date(amanha - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    limparPesquisa() {
      this.listaAgenda = [];
      this.historico = [];
    },
    consultaConfirmada() {
      // this.closeDialogValidarConsulta();
      this.dialogValidarConsulta = false;
      this.$router.push("dashboard-cliente");
    },
  },
};
</script>
<style scoped>
.header-day {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background-color: #1daf80;
  color: white;
  font-weight: bold;
}
.add-btn {
  background-color: #1daf80;
  color: white;
}
.bg-edit {
  background-color: #ffebcf;
}
.bg-create {
  /* background-color: #cff1ff; */
  background-color: white;
}
.btn-number {
  font-weight: bold;
}
.style-form {
  text-align: left;
}
.style-form label {
  font-weight: bold;
  margin-bottom: 0px;
}
.btn-mob {
  width: 100%;
  border-radius: 20px;
  padding: 20px 0px !important;
}
.card-mobile {
  display: flex;
  align-items: stretch;
  border-radius: 10px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.card-mobile .dia {
  display: flex;
  border-radius: 10px 0px 0px 10px;
  padding: 10px;
}
.dia.header-day {
  height: inherit;
  min-width: 60px;
  display: block;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.card-mobile .info-agendamento {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 0px 5px 0px 10px;
  text-align: start;
}
.medico {
  font-weight: lighter;
}
.medico span {
  font-weight: bold;
}
@media (max-width: 575.98px) {
}
</style>
