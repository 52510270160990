<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Modalidade de Consulta</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <v-container>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-10">
            <h3>
              Agendamento: {{ infos.diaAgendamento }} -
              {{ infos.horaAgendamento }}
            </h3>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-10">
            <v-card
              v-if="false"
              elevation="2"
              outlined
              class="my-5 py-3 px-4"
              :disabled="!modalidadeMedCar"
              :class="
                !modalidadeMedCar
                  ? 'card-disabled'
                  : modalidadeSelecionada == 'MedicinaCarinho'
                  ? ' card-med-selected'
                  : 'card-med'
              "
              @click="selecionarModalidade('MedicinaCarinho')"
            >
              <h4 class="text-start mb-3">
                Medicina com Carinho
              </h4>
              <hr />
              <div class="row justify-content-between">
                <div class="col-4 text-start pl-4">
                  <span class="text-start"
                    >Pet:
                    {{
                      medicinaCarinho.petNome ? medicinaCarinho.petNome : "-"
                    }}</span
                  >
                </div>
                <div class="col-4 text-start pl-4">
                  <span class="text-start"
                    >Número Cartão:
                    {{
                      medicinaCarinho.numCard ? medicinaCarinho.numCard : "-"
                    }}</span
                  >
                </div>
                <div class="col-4 text-start pl-4">
                  <span class="text-start"
                    >Cartão
                    {{
                      medicinaCarinho.telemedicina == "S"
                        ? "Válido"
                        : "Inválido"
                    }}</span
                  >
                </div>
              </div>
            </v-card>
            <v-card
              elevation="2"
              outlined
              class="my-5 py-3 px-4"
              :disabled="!modalidadeVoucherAnual"
              :class="
                !modalidadeVoucherAnual
                  ? 'card-disabled'
                  : modalidadeSelecionada == 'Anual'
                  ? 'card-med-selected'
                  : 'card-med'
              "
              @click="selecionarModalidade('Anual')"
            >
              <h4 class="text-start mb-3">
                Atendimento Anual
              </h4>
              <hr />
              <div class="row justify-content-between">
                <div class="col-4 text-start pl-4">
                  <span class="text-start"
                    >Pet: {{ anual.petNome ? anual.petNome : "-" }}</span
                  >
                </div>
                <div class="col-4 text-start pl-4">
                  <span class="text-start"
                    >Vencimento:
                    {{
                      anual.dataVencimento ? anual.dataVencimento : "-"
                    }}</span
                  >
                </div>
                <div class="col-4 text-start pl-4">
                  <span class="text-start"
                    >Modalidade:
                    {{ anual.idVoucher ? "Válido" : "Inválido" }}</span
                  >
                </div>
              </div>
            </v-card>
            <v-card
              elevation="2"
              outlined
              class="my-5 py-3 px-4"
              :disabled="!modalidadeVoucherAvulso"
              :class="
                !modalidadeVoucherAvulso
                  ? 'card-disabled'
                  : modalidadeSelecionada == 'Avulso'
                  ? ' card-med-selected'
                  : 'card-med'
              "
              @click="selecionarModalidade('Avulso')"
            >
              <h4 class="text-start mb-3">
                Atendimento Avulso
              </h4>
              <hr />
              <div class="row justify-content-between">
                <div class="col-4 text-start pl-4">
                  <span class="text-start"
                    >Pet: {{ avulso.petNome ? avulso.petNome : "-" }}</span
                  >
                </div>
                <div class="col-4 text-start pl-4">
                  <span class="text-start"
                    >Modalidade:
                    {{ avulso.idVoucher ? "Válido" : "Inválido" }}</span
                  >
                </div>
              </div>
            </v-card>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-10 d-flex justify-content-between layout">
            <!-- Pagseguro -->
            <v-btn
              :dark="true"
              :raised="true"
              color="#1daf80"
              class="mb-3 mob-btn"
              @click="dialogPagamento = true"
            >
              Adquirir
            </v-btn>
            <v-btn
              dark
              raised
              color="#1daf80"
              class="mb-3 mob-btn"
              @click="dialogMedicinaCarinho = true"
            >
              Informar Medicina com Carinho
            </v-btn>

            <!-- Pagseguro -->
            <div class="style-confirmar">
              <v-btn
                :dark="modalidadeSelecionada == '' ? false : true"
                :raised="modalidadeSelecionada == '' ? false : true"
                color="#1daf80"
                class="mb-3 mob-btn"
                :disabled="modalidadeSelecionada == '' ? true : false"
                @click="atender()"
              >
                Confirmar
              </v-btn>
            </div>
          </div>
        </div>
        <v-dialog v-model="dialogMedicinaCarinho" max-width="500px">
          <modal-medicina-carinho
            v-if="dialogMedicinaCarinho"
            :infos="infos"
            @close="dialogMedicinaCarinho = false"
            @cadastrado="cadastrado"
            @cartaoValido="cartaoValido"
          />
        </v-dialog>
        <v-dialog v-model="dialogSosRs" max-width="500px">
          <modal-sos
            v-if="dialogSosRs"
            :infos="infos"
            @close="dialogSosRs = false"
            @cadastrado="cadastradoSos"
            @cartaoValido="cartaoValido"
          />
        </v-dialog>
        <v-dialog fullscreen v-model="dialogPagamento" max-width="500px">
          <modal-pagamento
            v-if="dialogPagamento"
            :infos="infos"
            @close="dialogPagamento = false"
            @pago="pago($event)"
          />
        </v-dialog>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import MedicinaCarinhoService from "@/services/medicina_carinho_service.js";
import AgendaEspecialidadeService from "@/services/agendamento_especialidade_recepcao_service.js";
import ConfirmarConsultaService from "@/services/confirmar_agendamento_service.js";
import ModalPagamento from "@/components/pagamento/dialog_pagamento.vue";
import ModalMedicinaCarinho from "@/components/medicina_carinho/cadastro_medicina_carinho.vue";
import ModalSos from "@/components/medicina_carinho/cadastro_sos_rs.vue";

export default {
  mixins: [Mixin],
  components: {
    ModalPagamento,
    ModalMedicinaCarinho,
    ModalSos,
  },
  props: {
    infos: {
      type: Object,
      // required: true,
      required: false,
    },
  },
  data() {
    return {
      // infos: {
      //   petId: 50,
      //   petNome: "Pet do Euler",
      //   id_usuario: 5185,
      // },
      numTelefone: JSON.parse(sessionStorage.vuex).usuario.dados.telefone_1,
      id_usuario: JSON.parse(sessionStorage.vuex).usuario.id,
      dialogPagamento: false,
      dialogMedicinaCarinho: false,
      dialogSosRs: false,
      produtosLista: [],
      // Informação Produto
      produtosSelected: "",
      modalidadeMedCar: false,
      modalidadeVoucherAvulso: false,
      modalidadeVoucherAnual: false,
      modalidadeSelecionada: "",
      modelo: {},
      medicinaCarinho: {
        numCard: "",
        petNome: "",
        telemedicina: "",
      },
      avulso: {
        idVoucher: "",
        petNome: "",
        serial: "",
      },
      anual: {
        idVoucher: "",
        petNome: "",
        serial: "",
        dataVencimento: "",
      },
      // Services
      services: {
        medicinaCarinhoService: MedicinaCarinhoService.build(),
        agendaEspecialidadeService: AgendaEspecialidadeService.build(),
        confirmarConsultaService: ConfirmarConsultaService.build(),
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getCartaoVoucher();
  },
  methods: {
    async getCartaoVoucher() {
      await this.services.medicinaCarinhoService
        .getCheckCartaoVoucher(this.infos.id_pet)
        .then(async (response) => {
          const { data } = await response.json();
          this.medicinaCarinho.numCard =
            data.medicina_cc.cartao_mcc.cartao_medicina_carinho;
          this.medicinaCarinho.petNome = data.medicina_cc.cartao_mcc.nome;
          this.medicinaCarinho.telemedicina =
            data.medicina_cc.cartao_mcc.telemedicina;
          if (this.medicinaCarinho.telemedicina == "S") {
            this.modalidadeMedCar = true;
          }
          for (let index = 0; index < data.vouchers.vouchers.length; index++) {
            const element = data.vouchers.vouchers[index];
            if (element.id_tipo == 1) {
              this.modalidadeVoucherAvulso = true;
              this.avulso.petNome = element.nome_pet;
              this.avulso.idVoucher = element.id;
              this.avulso.serial = element.serial;
            } else {
              this.modalidadeVoucherAnual = true;
              this.anual.petNome = element.nome_pet;
              this.anual.idVoucher = element.id;
              this.anual.serial = element.serial;
              this.anual.dataVencimento = this.formatarData(
                element.data_vencimento
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async confirmarConsulta(modelo) {
      await this.services.agendaEspecialidadeService
        .postConfirmarAgendamento(modelo)
        .then((response) => {
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao agendar consulta",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Consulta agendada com sucesso",
            color: "sucess",
          });
          this.$emit("close");
        });
    },
    async pago(infoCompra) {
      if (infoCompra) {
        const modelo = {
          id_agendamento: parseInt(this.infos.id_agendamento),
          id_voucher: parseInt(infoCompra.voucher.id),
          // serial_voucher: infoCompra.voucher.serial,
        };
        await this.services.confirmarConsultaService.postAgendamentoVoucher(
          {
            onSucess: (status) => (body) => {
              if (status !== 200) {
                console.log(body);
                this.$_ACTIONS_showSnackbarMessage({
                  message: "Erro mo Voucher",
                  color: "error",
                });
                return;
              }
              this.$_ACTIONS_showSnackbarMessage({
                message: "Consulta Confirmada!",
                color: "sucess",
              });
              // this.$emit("close");
              this.$emit("confirmado");
            },
            onError: (message) => {
              this.$_ACTIONS_showSnackbarMessage({
                message: message || this.$global.messages.internalServerError,
                color: "error",
              });
            },
            onEnd: () => {},
          },
          modelo
        );
      }
      this.getCartaoVoucher();
      this.dialogPagamento = false;
    },
    cadastrado() {
      this.dialogMedicinaCarinho = false;
      this.getCartaoVoucher();
    },
    cadastradoSos() {
      this.dialogSosRs = false;
      this.getCartaoVoucher();
    },
    async atender() {
      let modelo = {};
      switch (this.modalidadeSelecionada) {
        case "Avulso":
          modelo = {
            id_agendamento: parseInt(this.infos.id_agendamento),
            id_voucher: parseInt(this.avulso.idVoucher),
            // serial_voucher: this.avulso.serial,
          };
          break;
        case "Anual":
          modelo = {
            id_agendamento: parseInt(this.infos.id_agendamento),
            id_voucher: parseInt(this.anual.idVoucher),
            // serial_voucher: this.anual.serial,
          };
          break;
        case "MedicinaCarinho":
          modelo = {
            id_usuario: this.id_usuario,
            id_pet: this.infos.id_pet,
            id_loja: 1,
            id_agendamento: this.infos.id_agendamento,
          };
          // await this.services.confirmarConsultaService.postConfirmarConsultaMCC(
          //   {
          //     onSucess: (status) => (body) => {
          //       if (status === 200) {
          //         this.$_ACTIONS_showSnackbarMessage({
          //           message:
          //             body.message || this.$global.messages.internalServerError,
          //           color: "sucess",
          //         });
          //       } else {
          //         this.$_ACTIONS_showSnackbarMessage({
          //           message:
          //             body.message || this.$global.messages.internalServerError,
          //           color: "error",
          //         });
          //       }
          //     },
          //     onError: (message) => {
          //       this.$_ACTIONS_showSnackbarMessage({
          //         message: message || this.$global.messages.internalServerError,
          //         color: "error",
          //       });
          //     },
          //     onEnd: () => {},
          //   },
          //   modelo
          //   // modeloTeste
          // );
          break;
      }
      await this.services.confirmarConsultaService.postAgendamentoVoucher(
        {
          onSucess: (status) => (body) => {
            if (status !== 200) {
              console.log(body);
              this.$_ACTIONS_showSnackbarMessage({
                message: "Erro mo Voucher",
                color: "error",
              });
              return;
            }
            this.$_ACTIONS_showSnackbarMessage({
              message: "Consulta Confirmada!",
              color: "sucess",
            });
            this.$emit("confirmado");
          },
          onError: (message) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: message || this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {},
        },
        modelo
      );
    },
    formatarData(data_requisicao) {
      if (data_requisicao) {
        const arrData = data_requisicao.split("-");
        return `${arrData[2]}/${arrData[1]}/${arrData[0]}`;
      } else {
        return "Sem Data";
      }
    },
    selecionarModalidade(modalidade) {
      this.modalidadeSelecionada = modalidade;
    },
    cartaoValido() {
      this.modalidadeMedCar = false;
      this.$emit("confirmado");
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
.card-disabled {
  background-color: rgb(201, 201, 201);
  border: 3px solid rgb(47, 47, 47);
}
.card-med {
  background-color: rgb(220, 217, 254);
  border: 3px solid rgb(34, 27, 111);
}
.card-med-selected {
  background-color: rgb(209, 231, 224);
  border: 3px solid rgb(29, 175, 128);
}
.layout {
  gap: 10px;
}
.style-confirmar {
  flex: 1;
  text-align: end;
}
@media (max-width: 575.98px) {
  .mob-btn {
    width: 100%;
  }
  .layout {
    flex-direction: column;
  }
}
</style>
